import { NativeConfigWebviewExtraDataScheduleTelehealth } from '@global/utils/native/native-config';
import { CustomWebviewMessageForTeleatendimentoAgendar } from '@global/utils/native/native-custom-webview-message';
import { GradientBackground } from '@lp-root/src/components/atm.background/background.styled';
import WebviewLayout from '@lp-root/src/components/org.layout/webview-layout.component';
import { Query } from '@lp-root/src/data/graphql/local-graphql-types';
import { ScheduleFormRowWrapper } from '@lp-root/src/modules/telehealth/schedule-page/schedule-form-row-wrapper.component';
import { PageProps } from '@lp-root/src/utils/local-types';
import { useCloseMessengerModalCallback } from '@web/atomic/obj.custom-hooks/close-messenger-modal.hook';
import { useQueryParams } from '@web/atomic/obj.custom-hooks/query-params';
import { NativeHelper } from '@web/data/native.helper';
import { createInsomniacDataSource } from '@web/data/vigilantes.datasource.factory';
import * as React from 'react';

interface AgendarPageQueryParams {
  health_carer_id?: string;
  health_carer_name?: string;
}

const AgendarPage: React.FunctionComponent<PageProps<unknown, Query>> = () => {
  const userId = NativeHelper.userId;
  const datasource = React.useRef(createInsomniacDataSource());
  const queryParams = useQueryParams<AgendarPageQueryParams>();
  const hcpId = queryParams.health_carer_id;
  const { first_name, last_name, email, birthdate, phone, document, next_available_appointment_date } =
    (NativeHelper.extraData as NativeConfigWebviewExtraDataScheduleTelehealth) ?? {};

  const { close } = useCloseMessengerModalCallback();
  const handleScheduleSuccess = () => {
    const msg: CustomWebviewMessageForTeleatendimentoAgendar = {
      type: 'custom',
      id: 'success',
    };
    NativeHelper.postMessage(msg);
    close();
  };

  return (
    <WebviewLayout hideThemeChanger skipMessengerExtensionLoad>
      <GradientBackground level={1}>
        {userId && hcpId && (
          <ScheduleFormRowWrapper
            datasource={datasource.current}
            userId={userId}
            hcpId={hcpId}
            hcpName={queryParams.health_carer_name}
            nextAvailableAppointmentDate={next_available_appointment_date}
            onScheduleSuccess={handleScheduleSuccess}
            signUpData={{
              initialBirthdate: birthdate,
              initialDocument: document,
              initialEmail: email,
              initialFirstName: first_name,
              initialLastName: last_name,
              initialPhone: phone,
            }}
          />
        )}
      </GradientBackground>
    </WebviewLayout>
  );
};

export default AgendarPage;
